import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

const SinglePage = () => {
  const [jsonData, setJsonData] = useState(null);
  const { id } = useParams();
  const [error , setError] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://backendadmin.azwaaja.com/api/blogs/${id}`);
        const data = await response.json();
        setJsonData(data[0]);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching JSON:', error);
        setError(true);
      }
    };

    fetchData();
  }, [id]);
  const formatDate = (isoString) => {
    const date = new Date(isoString);
    const day = date.getUTCDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getUTCFullYear();
    return `${day} ${month} ${year}`;
  };

  console.log(jsonData);


  if (loading) return (<div className="min-h-screen flex items-center justify-center">
    {/* Loading spinner or animation */}
    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
  </div>);

  if (jsonData.stack) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <div className="text-red-600 text-2xl mb-4">Blog not found</div>
          <Link to="/blog" className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700">
            Go back to blogs
          </Link>
        </div>
      </div>
    );
  }
  if (error) return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="text-center">
        <div className="text-red-600 text-2xl mb-4">Something went wrong</div>
      </div>
    </div>
  );

  if (jsonData.length === 0) {
    return <div className="flex justify-center items-center min-h-screen">No Blog available</div>;
  }



  return (
    <>{!jsonData ? (<div className="min-h-screen flex items-center justify-center">
      <div className="text-center">
        <div className="text-red-600 text-2xl mb-4">Blog not found</div>
        <Link to="/blog" className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700">

          Go back to blogs
        </Link>
      </div>
    </div>) :
    
    (<>
      <section className="container mx-auto mt-4 max-w-[1016px] px-4 sm:px-10 xl:px-0">
        <div className="flex flex-row flex-wrap break-all">
          <Link to={"/blog"} className="w-fit">
            <span className="break-all pl-1 font-semibold capitalize text-azwaajacolor1 hover:text-azwaajacolor2 cursor-pointer">
              Azwaaja Blog<span>| </span>
            </span>
          </Link>
          <Link to={`/blogs/${jsonData.category}`} className="w-fit">
            <span className="break-all pl-1 font-semibold capitalize text-azwaajacolor1 hover:text-azwaajacolor2 cursor-pointer">
              {jsonData?.category}  <span>| </span>
            </span>
          </Link>
          <p className="break-all pl-1 font-semibold capitalize text-azwaajacolor1 hover:text-azwaajacolor2 cursor-pointer">
            {jsonData?.title}
          </p>
        </div>
      </section>

      <div className="pt-[24px] sm:pt-[64px]"></div>

      <section className="container mx-auto max-w-[1016px] px-4 sm:px-10 xl:px-0">
        <h1 className="text-[40px] font-bold leading-[48px] text-azwaajacolor1 hover:text-azwaajacolor2 -tracking-[1px] sm:text-[60px] sm:leading-[68px] sm:-tracking-[2px]">
          {jsonData.title}
        </h1>
        <div className="mt-[16px]"></div>
        <p className="text-[18px] leading-[27px] text-mm-mid-gray">
          Published: {formatDate(jsonData.publishDate)}
        </p>
      </section>

      <div className="pt-[40px]"></div>

      <section className="container mx-auto max-w-[1016px] sm:px-10 xl:px-0">
        <img
          className="w-full"
          src={jsonData.image}
          alt="Blog Image"
        />
      </section>

      <div className="pt-[48px]"></div>

      <section className="container mx-auto max-w-[1016px] px-4 sm:px-10 xl:px-0">
        <div className="gap-x-[51px] xl:flex">
          <div className="border-r-mm-heather/20 xl:w-full xl:pr-[51px]" dangerouslySetInnerHTML={{ __html: jsonData.description }} />
    
        </div>
      </section>


      <section className="container mx-auto max-w-[1016px] px-4 mt-10 mb-6 sm:px-10 xl:px-0">
        <div className="text-center ">
          <Link to={`/blog`} className="text-[18px] bg-azwaajacolor2 p-4 rounded-full text-white font-semibold leading-[27px] text-mm-mid-gray">
            View More
          </Link>
          
        </div>
        </section> </>)}
      
      </>
  );
};

export default SinglePage;
