import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

const SingleEvent = () => {
  const [eventData, setEventData] = useState(null);
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const response = await fetch(
          `https://backendadmin.azwaaja.com/api/events/${id}`
        );
        const data = await response.json();
        setEventData(data);
      } catch (error) {
        console.error("Error fetching event:", error);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchEvent();
  }, [id]);

  const formatDate = (isoString) => {
    const date = new Date(isoString);
    return date.toLocaleDateString(undefined, {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  if (loading)
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
      </div>
    );

  if (error)
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-red-600 text-2xl">Something went wrong.</div>
      </div>
    );

  if (!eventData)
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div>No event found.</div>
      </div>
    );

  return (
    <>
      <section className="container mx-auto mt-4 max-w-[1016px] px-4 sm:px-10 xl:px-0">
        <div className="flex flex-row flex-wrap break-all mb-4">
          <Link to="/events" className="w-fit">
            <span className="break-all pl-1 font-semibold capitalize text-azwaajacolor1 hover:text-azwaajacolor2 cursor-pointer">
              Azwaaja Events<span> | </span>
            </span>
          </Link>
          <p className="break-all pl-1 font-semibold  text-azwaajacolor1">
            {eventData.eventName}
          </p>
        </div>
      </section>

      <div className="pt-[24px] sm:pt-[64px]"></div>

      <section className="container mx-auto max-w-[1016px] px-4 sm:px-10 xl:px-0">
        <h1 className="text-[40px] font-bold leading-[48px] text-azwaajacolor1 hover:text-azwaajacolor2 -tracking-[1px] sm:text-[60px] sm:leading-[68px] sm:-tracking-[2px]">
          {eventData.eventName}
        </h1>
        <div className="mt-[16px]"></div>
        <p className="text-[18px] leading-[27px] text-mm-mid-gray">
          Date: {formatDate(eventData.date)}
        </p>
        <p className="text-gray-500 mb-2">Location: {eventData.location}</p>
      </section>

      <div className="pt-[40px]"></div>

      <section className="container mx-auto max-w-[1016px] sm:px-10 xl:px-0">
        <img
          src={eventData.image}
          alt={eventData.eventName}
          className="w-full h-64 object-cover mb-4"
        />
      </section>

      <div className="pt-[48px]"></div>

      <section className="container mx-auto max-w-[1016px] px-4 sm:px-10 xl:px-0">
        <div
          className="border-r-mm-heather/20"
          dangerouslySetInnerHTML={{ __html: eventData.description }}
        />
      </section>

      <section className="container mx-auto max-w-[1016px] px-4 mt-10 mb-6 sm:px-10 xl:px-0">
        <div className="text-center">
          <a
            href={eventData.link}
            target="_blank"
            className="text-blue-600 hover:underline"
          >
            More Info
          </a>
        </div>
      </section>

      <section className="container mx-auto max-w-[1016px] px-4 mt-10 mb-6 sm:px-10 xl:px-0">
        <div className="text-center ">
          <Link
            to={`/event`}
            className="text-[18px] bg-azwaajacolor2 p-4 rounded-full text-white font-semibold leading-[27px] text-mm-mid-gray"
          >
            View More
          </Link>
        </div>
      </section>
    </>
  );
};

export default SingleEvent;
